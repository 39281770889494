import React, { useEffect, useMemo, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { useWidgets } from '../hooks/useWidgets';
import { useStatistics } from '../hooks/useStatistics';
import { useExecutorsWidgets } from '../hooks/useExecutorsWidgets';
import { useToggle } from '../../../hooks/useToggle';
import { parseFromPars } from '../../../utils/parseUrlParams';
import axiosApiInstance from '../../../requests/utils/api';
import checkRole from '../../../utils/checkRole';
import roles from '../../customers/roles/roles';
import ContentManagerDashboard from '../../customers/ContentManagerDashboard';
import groupsRoles from '../../customers/roles/groupsRoles';
import GroupsNavigation from './GroupsNavigation';
import MainStats from './widgets/MainStats';
import TasksWidget from './widgets/TasksWidget';
import TasksList from './Tasks/TasksList';



const WidgetsPanel = () => {
  const { lang, user, users } = useSelector(({ language, user, users }) => ({
    lang: language.lang,
    user: user.user,
    users,
  }), shallowEqual);
  const [ widgets, isWidgetsLoading ] = useWidgets();
  const [ statistics, isStatisticsLoading ] = useStatistics();
  const [ executors, isExecutorsLoading ] = useExecutorsWidgets();
  const [ showTasksList, toggleTasksList ] = useToggle();
  const [ loading, toggleLoader ] = useToggle();
  const location = useLocation();
  const [ tasks, setTasks ] = useState([]);
  const [ currentTasksList, setCurrentTasksList ] = useState(null);

  useEffect(() => {
    if (showTasksList) {
      toggleTasksList();
    }
  }, [ location.search ]);

  const showIdleExecutors = (executors) => {
    if (!executors || !executors.length) {
      return '---';
    }

    return executors.map((executor, index) => {
      const separator = index === executors.length - 1 ? ' ' : ' | ';

      return <span key={executor.id}><Link to={`/freelancers/${executor.id}`}>{executor.username}</Link>{separator}</span>;
    });
  };

  const fetchTasks = async (type) => {
    try {
      if ((showTasksList && currentTasksList === type) || !showTasksList) {
        toggleTasksList();
      }

      const queryParams = parseFromPars(location.search);

      toggleLoader();
      const response = await axiosApiInstance.get(`/dashboards/tasks?group_id=${queryParams.group_id}&type=${type}`);

      setTasks(response.data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      toggleLoader();
    }
  };

  const currentUser = useMemo(() => {
    return users.find((userItem) => userItem.id === user.id);
  }, [ users ]);


  return (
    <div>
      <GroupsNavigation />
      <Row className="flex justify-content-center">
        <Col sm={3} xxl={2}>
          <MainStats
            title={lang['DASHBOARD.DEADLINE_EXCEEDED']}
            loading={isWidgetsLoading}
            value={widgets.overdue_tasks}
            variant="danger"
            onClick={async () => {
              await setCurrentTasksList('overdue');
              fetchTasks('overdue');
            }}
          />
        </Col>
        <Col sm={3} xxl={2}>
          <MainStats
            title={lang['DASHBOARD.7_DAYS_TO_DEADLINE']}
            loading={isWidgetsLoading}
            value={widgets.upcoming_deadline_tasks}
            variant="warning"
            onClick={async () => {
              await setCurrentTasksList('7_days_to_deadline');
              fetchTasks('7_days_to_deadline');
            }}
          />
        </Col>
        <Col sm={3} xxl={2}>
          <MainStats
            title={lang['DASHBOARD.EXECUTORS_CHECKING']}
            loading={isWidgetsLoading}
            value={widgets.checking_executors_tasks}
          />
        </Col>
      </Row>
      <section className="mt-4">
        <Row className="align-items-start">
          <Col md={4} className="my-4">
            <h5><strong>{lang['DASHBOARD.ASSIGN_TASKS']}</strong></h5>
            <TasksWidget loading={isExecutorsLoading}>
              <TasksWidget.Item>
                <span>{lang['DASHBOARD.CHOOSING_ARTIST']}</span>
                {checkRole(currentUser?.roles ?? [], [ roles.administrator.key ]) ? (
                  <span className="btn btn-primary">{executors.artists.choosingArtistTasksAmount}</span>
                ) : <Link className="btn btn-primary" to="/tasks/type/artist_choosing/all">{executors.artists.choosingArtistTasksAmount}</Link>}
              </TasksWidget.Item>
              <TasksWidget.Item>
                <>
                  <span>{lang['DASHBOARD.CHOOSING_COLORIST']}</span>
                  {checkRole(currentUser?.roles ?? [], [ roles.administrator.key ]) ? (
                    <span className="btn btn-primary">{executors.colorists.choosingColoristTasksAmount}</span>
                  ) : <Link className="btn btn-primary" to="/tasks/type/designer_choosing/all">{executors.colorists.choosingColoristTasksAmount}</Link>}
                </>
              </TasksWidget.Item>
            </TasksWidget>
          </Col>
          <Col md={8} className="my-4">
            <h5><strong>{lang['DASHBOARD.IDLE_EXECUTORS']}</strong></h5>
            <TasksWidget loading={isExecutorsLoading}>
              <TasksWidget.Item>
                <p><span>{lang['BONUSES.ARTISTS']}:</span> {showIdleExecutors(executors.artists.idle)}</p>
              </TasksWidget.Item>
              <TasksWidget.Item>
                <p>{lang['BONUSES.DESIGNERS']}: {showIdleExecutors(executors.colorists.idle)}</p>
              </TasksWidget.Item>
            </TasksWidget>
          </Col>
        </Row>
        <Row className="my-4 align-items-end">
          <Col md={4}>
            <h5><strong>{lang['DASHBOARD.STATISTICS']}</strong></h5>
            <TasksWidget loading={isStatisticsLoading}>
              <TasksWidget.Item>
                <span>{lang['DASHBOARD.ARTIST_TASKS_INACTIVE_30_DAYS']}</span>
                <Button
                  variant="warning" onClick={async () => {
                    if (statistics.artists.tasks.inactive_30_days > 0) {
                      await setCurrentTasksList('arist_tasks_inactive_30_days');
                      fetchTasks('arist_tasks_inactive_30_days');
                    }
                  }}
                >{statistics.artists.tasks.inactive_30_days}</Button>
              </TasksWidget.Item>
              <TasksWidget.Item>
                <span>{lang['DASHBOARD.DESIGNER_TASKS_INACTIVE_30_DAYS']}</span>
                <Button
                  variant="warning" onClick={async () => {
                    if (statistics.designers.tasks.inactive_30_days > 0) {
                      await setCurrentTasksList('colorist_tasks_inactive_30_days');
                      fetchTasks('colorist_tasks_inactive_30_days');
                    }
                  }}
                >{statistics.designers.tasks.inactive_30_days}</Button>
              </TasksWidget.Item>
            </TasksWidget>
          </Col>
          <Col md={4}>
            <TasksWidget loading={isStatisticsLoading}>
              <TasksWidget.Item>
                <span>{lang['DASHBOARD.ARTIST_TASKS_FINISHED_DURING_7_DAYS']}</span>
                <span className="btn btn-primary">{statistics.artists.tasks.finished_during_7_days}</span>
              </TasksWidget.Item>
              <TasksWidget.Item>
                <span>{lang['DASHBOARD.DESIGNER_TASKS_FINISHED_DURING_7_DAYS']}</span>
                <span className="btn btn-primary">{statistics.designers.tasks.finished_during_7_days}</span>
              </TasksWidget.Item>
            </TasksWidget>
          </Col>
          <Col md={4}>
            <TasksWidget loading={isStatisticsLoading}>
              <TasksWidget.Item>
                <span>{lang['DASHBOARD.ARTIST_TASKS_FINISHED_DURING_30_DAYS']}</span>
                <span className="btn btn-primary">{statistics.artists.tasks.finished_during_30_days}</span>
              </TasksWidget.Item>
              <TasksWidget.Item>
                <span>{lang['DASHBOARD.DESIGNER_TASKS_FINISHED_DURING_30_DAYS']}</span>
                <span className="btn btn-primary">{statistics.designers.tasks.finished_during_30_days}</span>
              </TasksWidget.Item>
            </TasksWidget>
          </Col>
        </Row>
      </section>
      <section className="mt-8">
        {showTasksList && (
          <TasksList
            items={tasks}
            loading={loading}
          />
        )}
      </section>
      {currentUser?.roles && checkRole(currentUser.roles, groupsRoles.editors.all) && (
        <section className="kt-mt-40">
          <ContentManagerDashboard />
        </section>
      )}
    </div>
  );
};

export default WidgetsPanel;
