import React from 'react';
import { connect } from 'react-redux';
import { selectors } from '../../ducks/builder';
import {isFabros} from "../../../app/utils/isFabros";
import Modal from '../../../app/widgets/Modal';
import Button from 'react-bootstrap/Button';
import FormTestersMode from '../../../app/components/FormTestersMode';
import Tooltip from 'react-bootstrap/Tooltip';
import getTestModeParams from '../../../app/utils/getTestModeParams';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import checkRole from '../../../app/utils/checkRole';
import { isProd } from '../../../app/utils/isProd';
import roles from '../../../app/pages/customers/roles/roles';



class Footer extends React.Component {
  state = {
    isActiveTestersModeModal: false
  }

  render () {
    const today = new Date().getFullYear();
    const testModeParams = getTestModeParams();
    const developerId = testModeParams?.developer_id ?? null;
    const isActiveTestMode = !!developerId;
    const { user } = this.props;
    const userInfo = user?.user;

    const isSpecialUserOnProd = () => {
      return isProd && userInfo?.id && user.roles &&
        ((checkRole(user.roles, [ roles['content-manager'].key ]) && userInfo.id === 695)
        || (checkRole(user.roles, [ roles['lead-coloring-editor'].key ]) && userInfo.id === 709)
        || (checkRole(user.roles, [ roles['coloring-designer'].key ]) && userInfo.id === 607))
    }

    const isEnabledTestMode = () => {
      return !isProd || isSpecialUserOnProd();
    };

    const getUsersList = () => {
      if (isSpecialUserOnProd() && this.props.users) {
        const specialUserIds = [ 695, 709, 607 ];
        return this.props.users.filter(user => specialUserIds.includes(user.id));
      }
      return this.props.users;
    }

    const toggleTestersModeModal = (status) => {
      return () => {
        this.setState({ isActiveTestersModeModal: status })
      };
    };

    return (
      <div
        className={`kt-footer ${this.props.footerClasses} kt-grid__item kt-grid kt-grid--desktop kt-grid--ver-desktop no-print`}
        id="kt_footer"
      >
        <Modal
          title="Switch account"
          show={this.state.isActiveTestersModeModal}
          close={toggleTestersModeModal(false)}
          footer={null}
        >
          <FormTestersMode
            usersList={getUsersList()}
            isActiveTestMode={isActiveTestMode}
            testModeParams={testModeParams}
          />
        </Modal>
          {!this.state.isActiveTestersModeModal && isEnabledTestMode() && (
              <div className="test-settings">
                <OverlayTrigger
                  placement="left"
                  overlay={(
                    <Tooltip id="testMode">
                      Test mode
                    </Tooltip>
                  )}
                >
                  <Button
                    type="secondary"
                    shape="circle"
                    className={`ant-btn-icon-only ant-btn-circle da-primary-shadow ${isActiveTestMode ? 'progress-btn-circle' : 'warning-btn-circle'}`}
                    onClick={toggleTestersModeModal(true)}
                  >
                    <i className="flaticon2-settings" />
                  </Button>
                </OverlayTrigger>
              </div>
          )}
        <div className={`kt-container ${this.props.footerContainerClasses}`}>
          <div className="kt-footer__copyright">
            {today.toString()}&nbsp;&copy;&nbsp;
            <a
              href={isFabros ? "https://fabros-team.com/" : "https://xflowgames.com/"}
              target="_blank"
              rel="noopener noreferrer"
              className="kt-link"
            >
              {isFabros ? 'Fabros' : 'X-Flow'}
            </a>
          </div>
          <div className="kt-footer__terms">
            <a
              rel="noopener noreferrer"
              className="kt-link"
              onClick={this.props.showTerms}
            >
              {this.props.lang['CUSTOMER.MY_CONTRACT']}
            </a>
          </div>
          <div className="kt-footer__privacy">
            <a
              rel="noopener noreferrer"
              className="kt-link"
              onClick={this.props.showPrivacy}
            >
              {this.props.lang[isFabros ? 'GLOBAL.PRIVACY_POLICY' : 'GLOBAL.XFLOW_PRIVACY_POLICY']}
            </a>
          </div>
          <div className="kt-footer__menu">
            <a
              href={isFabros ? "mailto:support@bee-freelancer.com" : "mailto:support@xflow-freelancer.com"}
              target="_blank"
              rel="noopener noreferrer"
              className="kt-footer__menu-link kt-link"
            >
              {isFabros ? 'support@bee-freelancer.com' : 'support@bee-freelancer.app'}
            </a>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  lang: store.language.lang,
  users: store.users,
  user: store.user,
  footerClasses: selectors.getClasses(store, {
    path: 'footer',
    toString: true,
  }),
  footerContainerClasses: selectors.getClasses(store, {
    path: 'footer_container',
    toString: true,
  }),
});

export default connect(mapStateToProps)(Footer);
